<template>
  <div class="container-fluid row m-0 p-0 no-gutters">
    <section class="d-flex flex-column justify-content-start m-0 py-0 px-5 col">
      <div
        class="d-flex justify-content-between align-items-end mt-4 pb-3"
        style="border-bottom: 1px solid var(--primary)"
      >
        <div class="d-flex">
          <h5 class="text-primary-light mb-0">Find your developer</h5>
          <v-icon color="#000" size="20px" class="ms-2">
            mdi-chevron-right
          </v-icon>
          <h5 v-if="developer" class="text-primary mb-0">
            {{ developer.name }}
          </h5>
        </div>
        <button class="rounded-button bg-neutral-light px-5 border-primary">
          <h5
            @click="$router.push({ name: 'CreateProject1' })"
            class="text-neutral-dark font-weight-400 mb-0 text-center cursor-pointer"
          >
            Take me to Dashboard
          </h5>
        </button>
      </div>

      <h2 class="text-neutral-dark mt-5">
        Please Select Your Residential Project
      </h2>
      <div class="d-flex flex-wrap gap-5 mt-5">
        <developer-item
          class="wd-25p h-100"
          v-for="p in projects"
          :key="p.id"
          :developer="p"
          @click.native="
            $router.push({
              name: 'ProjectProperties',
              params: { developerId: developerId, projectId: p.id },
            })
          "
        />
      </div>
    </section>

    <section :class="['m-0 p-0 col-3 d-none d-md-block']">
      <img
        :src="require('/src/assets/images/compound-bg.jpeg')"
        alt="tech-bg"
        class="img-height img-section"
      />
    </section>
  </div>
</template>

<script>
import DeveloperItem from "@/components/DeveloperItem";
export default {
  name: "DeveloperProjects",
  components: { DeveloperItem },
  mounted() {
    this.$store.dispatch("developerProject/getProjects", this.developerId);
  },
  computed: {
    developerId() {
      return this.$route.params.developerId;
    },
    developer() {
      return this.$store.state.developers.developers.find(
        (p) => p.id === this.developerId
      );
    },
    projects() {
      return this.$store.state.developerProject.projects;
    },
  },
};
</script>

<style scoped lang="scss">
.img-height {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  object-fit: cover;
}

.img-section {
  animation: backgroundScroll ease-in-out 35s infinite;
}

@keyframes backgroundScroll {
  0% {
    object-position: 0;
  }
  50% {
    object-position: 100%;
  }

  100% {
    object-position: 0;
  }
}
</style>
